<template>
  <v-hover v-if="isVisible" v-slot="{ hover }">
    <v-sheet :elevation="hover ? 12 : 6" height="auto" min-width="350px"  max-width="600px" rounded="xl" href="" :style="cssVars" color="white" class="ma-2 flex-grow-1" >
      <v-container class="py-8">
        <v-row class="" align="center">
          <v-col cols="4" align-self="center">
            <v-row justify="center">
              <slot name="icon"></slot>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-row class="pr-10">
              <p class="text-h3 white--text">
                <slot name="title"></slot><br>
              </p>
            </v-row>
            <v-row>
              <v-btn rounded color="white">{{$t('entrar')}}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-hover>
</template>

<script>
import ConditionalRoleVisibility from "@/mixins/ConditionalRoleVisibility.vue"
export default {
  mixins: [ConditionalRoleVisibility],
  props: {
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    cssVars() {
      return {
        "--tile-color": `#${this.color}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.v-sheet {
  background-color: var(--tile-color) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .4s;
  
  .tile-title {
    color: #f0f0f0 !important;
  }
}
.centered {
  text-align: center;
}
</style>
