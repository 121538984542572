<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />
    
    <v-main class="pl-16">
      <v-container class="transition">
        <v-row justify="center">
          <v-col cols="12" lg="8" class="transition">
            <v-row justify="center" class="mt-sm-0 mt-md-12 pa-6 pa-md-0">
          <h1 class="text-h1 darkprimary--text">
            {{ $t("titulo home") }}
          </h1>
          <p>Mylogy es una plataforma ... exercitationem molestias est quos rerum sit inventore voluptate. Nihil cupiditate veniam qui. Aliquam dolorum voluptates odio et quo molestias veritatis. Ab adipisci quisquam voluptatibus vel consequatur nemo.</p>
        </v-row>
            <v-row justify="center" justify-md="start" class="">
            <tile-button :visibleFor="['BASIC', 'PRO', 'ADVANCED', 'ADMIN']" v-on:click.native="$router.push({ name: 'GeneticProfile' })" color="6F4AE6">
              <template v-slot:icon>
                <v-icon size="60" color="#ffffff" class="" >mdi-dna</v-icon >
              </template>
              <template v-slot:title>
                <span>{{ $t("Perfil Genético") }}</span>
              </template>
            </tile-button>

            <tile-button :visibleFor="['BASIC', 'PRO', 'ADVANCED', 'ADMIN']" v-on:click.native="$router.push({ name: 'PGXResults' })" color="381273">
              <template v-slot:icon>
                <v-icon size="60" color="#ffffff" class="" >mdi-pill</v-icon >
              </template>
              <template v-slot:title>
                <span>{{ $t("farmacogenetica") }}</span>
              </template>
            </tile-button>

            <tile-button :visibleFor="['PRO', 'BASIC', 'ADVANCED', 'ADMIN']" v-on:click.native="$router.push({ name: 'Explorer' })" color="1C093A">
              <template v-slot:icon>
                <v-icon size="60" color="#ffffff" class="" >mdi-book</v-icon >
              </template>
              <template v-slot:title>
                <span>{{ $t("EPG") }}</span>
              </template>
              <v-row justify="center">
                <v-btn rounded color="white">{{ $t("entrar") }}</v-btn></v-row>
            </tile-button>
            </v-row>
          </v-col>

        </v-row>
      </v-container>
      <!-- <v-container tag-section fluid id="europharma" class="mt-16 px-10 py-2">
        <v-row align="center">
          <v-col>
            <v-img src="@/assets/logo/logo-euroespes-blanco.png" max-width="140px" ></v-img>
          </v-col>
          <v-col>
            <a @click="goToExternal('https://euroespes.com/')" class="white--text">{{ $t("quienes_somos") }}</a>
          </v-col>
          <v-col>
            <a @click="goToExternal('https://mylogygenomics.com/faqs/')" class="white--text" >{{ $t("preguntas_frecuentes") }}</a>
          </v-col>
          <v-col>
            <a @click="goToExternal('https://mylogygenomics.com/contacto/')" class="white--text" >{{ $t("contacto") }}</a>
          </v-col>
          <v-col>
            <a @click="goToExternal('https://mylogygenomics.com/privacy-policy/')" class="white--text" >{{ $t("politica_privacidad") }}</a>
          </v-col>
          <v-col>
            <a @click="goToExternal('https://mylogygenomics.com/terminos-y-condiciones/')" class="white--text">{{ $t("terminos_y_condiciones") }}</a>
          </v-col>
        </v-row>
      </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import TileButton from "@/components/mylogy/TileButton.vue";


export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    TileButton
  },
  methods: {
    goToExternal(externalURL) {
      window.open(externalURL, "_blank");
    }
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {},
  mounted() {}
};
</script>

<style lang="scss">
.transition {
  transition: .5s !important;
}
#europharma {
  background: #1c093a;
  position: fixed;
  bottom: 0;
}
@media (max-width: 900px) {
  #europharma {
    position: sticky;
    bottom: -25%;
  }
}
a {
  text-decoration: none;
}

#panel-selector {
  .v-card {
    height: 400px;
    width: calc(33% - 33px);
    min-width: min-content;
    border: solid 2px #6f4be0;
    overflow: hidden;

    .title {
      font-size: calc(2em + 10%) !important;
    }

    .v-card__actions {
      height: 20%;

      .v-btn__content {
        color: #6f4be0 !important;font-weight: 500;
      }
    }
    .card-shape {
      position: absolute;
      background: #1c093a;
      width: 120%;
      height: 160px;
      bottom: -60px;
      //transform: rotate(-7deg);
      border-radius: 50%;
    }
  }
  .v-card * {
    word-break: normal;
  }
  .v-card:nth-child(1) {
    background: url("~@/assets/img/perfil-tarxeta.png") no-repeat bottom;
    background-position-y: 150px;
    background-size: 80%;
    transition: 0.3s;
    &:hover {
      background-position-y: 120px;
    }
  }
  .v-card:nth-child(2) {
    background: url("~@/assets/img/farmacogenetica-tarxeta.png") no-repeat
      bottom;
    background-position-y: 130px;
    background-size: 90%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
  .v-card:nth-child(3) {
    background: url("~@/assets/img/guia-tarxeta.png") no-repeat bottom;
    background-position-y: 130px;
    background-size: 100%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
}
</style>
